import React, { lazy, Suspense, useEffect } from "react";
import { Route, Routes } from "react-router-dom";
import { Navigate } from "react-router-dom";
import Cookies from "js-cookie";
import styles from "./styles.module.scss";
import PageHeader from "components/PageHeader";
import SideNav from "components/SideNav";
import { Spin } from "antd";

import useResources from "hooks/useResources";
import useProfile from "hooks/useProfile";

const Staff = lazy(() => import("pages/Staff"));
const Campaign = lazy(() => import("pages/Campaign"));
const RankCondition = lazy(() => import("pages/RankCondition"));
const DetailCampaign = lazy(() => import("pages/DetailCampaign"));
const Run = lazy(() => import("pages/Run"));
const Shoes = lazy(() => import("pages/Shoes"));
const Member = lazy(() => import("pages/Member"));
const ShoesModel = lazy(() => import("pages/ShoesModel"));
const RunDemo = lazy(() => import("pages/RunDemo"));
const Notification = lazy(() => import("pages/Notification"));
const Languages = lazy(() => import("pages/Languages"));
const NotificationDelay = lazy(() => import("pages/NotificationDelay"));
const DetailCampaignMission = lazy(() => import("pages/DetailCampaignMission"));
const Contact = lazy(() => import("pages/Contact"));
const Resource = lazy(() => import("pages/Resource"));
const SettingTerm = lazy(() => import("pages/Setting/Term"));
const SettingPolicy = lazy(() => import("pages/Setting/Policy"));
const Guides = lazy(() => import("pages/Guides"));
const GuideDetail = lazy(() => import("pages/GuideDetail"));
const Brand = lazy(() => import("pages/Setting/Brand"));
const ResouceContact = lazy(() => import("pages/Setting/Contact"));
const FAQ = lazy(() => import("pages/Setting/FAQ"));
const GifCode = lazy(() => import("pages/GiftCodeNoti"));
const GifCoin = lazy(() => import("pages/GiftCoin"));
const GifLottery = lazy(() => import("pages/GifLottery"));
const GiftHistory = lazy(() => import("pages/GiftHistory"));
const GiftLotteryDetail = lazy(() => import("pages/GiftLotteryDetail"));

const WinnerHistory = lazy(() => import("pages/WinnerHistory"));
const CoinAcquisitionHistory = lazy(() => import("pages/CoinAcquisitionHistory"));
const GiftManager = lazy(() => import("pages/GiftManager"));
const ManagerCompany = lazy(() => import("pages/ManagerCompany"));
const DetailCoinAcquisitionHistory = lazy(() => import("pages/DetailCoinAcquisitionHistory"));
const RandomGift = lazy(() => import("pages/RandomGift"));
const QuestManager = lazy(() => import("pages/QuestManager"));

const GuildSlide = lazy(() => import("pages/Setting/GuildSlide"));
const MemberAchievement = lazy(() => import("pages/MemberAchievement"));
const GuildAchivement = lazy(() => import("pages/GuildAchievement"));
const GuildAchievementYear = lazy(() => import("pages/GuildAchivementYear"));
const AppGuilde = lazy(() => import("pages/Setting/AppGuilde"));
const Feedback = lazy(() => import("pages/Setting/Feedback"));
const RunAchievement = lazy(() => import("pages/RunGuildAchievement"));
const DetailRankGuildAchievement = lazy(() => import("pages/DetailRankGuildAchievement"));

export default function AuthWrapper() {
  const token = Cookies.get("token") || "";
  const isAuthenticated = Boolean(token);
  const { resources } = useResources(isAuthenticated);
  const { profile } = useProfile(isAuthenticated);

  if (!isAuthenticated) return <Navigate to="/login" />;
  if (!resources || !profile) return null;
  return (
    <div className={styles.pageWrapper}>
      <SideNav />
      <div className={styles.mainWrapper}>
        <PageHeader />

        <div className={styles.pageContent}>
          <Suspense
            fallback={
              <div className="spin">
                <Spin />
              </div>
            }
          >
            <Routes>
              <Route path="/rank-condition" element={<RankCondition />} />
              <Route path="/languages" element={<Languages />} />
              <Route path="/notification-delay" element={<NotificationDelay />} />
              <Route path="/shoes" element={<Shoes />} />
              <Route path="/run-demo" element={<RunDemo />} />
              <Route path="/notification" element={<Notification />} />
              <Route path="/member" element={<Member />} />
              <Route path="/run-abnormal" element={<Run />} />
              <Route path="/run-achievement" element={<RunAchievement />} />
              <Route path="/shoes-model" element={<ShoesModel />} />
              <Route path="/campaign-detail" element={<DetailCampaign />} />
              <Route path="/campaign-detail-mission/:id" element={<DetailCampaignMission />} />
              <Route path="/campaign" element={<Campaign />} />
              <Route path="/staffs" element={<Staff />} />
              <Route path="/contacts" element={<Contact />} />
              <Route path="/resource" element={<Resource />} />
              <Route path="/resource/term" element={<SettingTerm />} />
              <Route path="/resource/policy" element={<SettingPolicy />} />
              <Route path="/guides" element={<Guides />} />
              <Route path="/guides/:id" element={<GuideDetail />} />
              <Route path="/guild-achievement-year" element={<GuildAchievementYear />} />
              <Route path="/campaign/:id/guild-achievement" element={<GuildAchivement />} />

              <Route path="/resource/brand" element={<Brand />} />
              <Route path="/resource/contact" element={<ResouceContact />} />
              <Route path="/resource/faq" element={<FAQ />} />
              <Route path="/resource/guild-slide" element={<GuildSlide />} />
              <Route path="/resource/app-guide" element={<AppGuilde />} />
              <Route path="/resource/feedback" element={<Feedback />} />

              <Route path="/gift-code" element={<GifCode />} />
              <Route path="/gift-coin" element={<GifCoin />} />
              <Route path="/gift-lottery" element={<GifLottery />} />
              <Route path="/gift-history" element={<GiftHistory />} />
              <Route path="/gift-lottery/:id" element={<GiftLotteryDetail />} />

              <Route path="/winner-history" element={<WinnerHistory />} />
              <Route path="/coin-acquisition-history" element={<CoinAcquisitionHistory />} />
              <Route path="/gift-manager" element={<GiftManager />} />
              <Route path="/manager-company" element={<ManagerCompany />} />
              <Route path="/coin-acquisition-history/:id" element={<DetailCoinAcquisitionHistory />} />
              <Route path="/random-gift" element={<RandomGift />} />
              <Route path="/quest-manager" element={<QuestManager />} />

              <Route path="/member-achievement" element={<MemberAchievement />} />
              <Route path="/detail-rank-guild-achievement" element={<DetailRankGuildAchievement />} />
            </Routes>
          </Suspense>
        </div>
      </div>
    </div>
  );
}
