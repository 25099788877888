import { sendGet, sendPost, sendPut } from "./axios";

// eslint-disable-next-line import/prefer-default-export
export const getListLanguageByEnvironment = (params: any) =>
  sendGet("/cms/language/init", params);
export const getListLanguageKey = (payload: any) =>
  sendPost("/cms/language/list-language-key", payload);
export const updateLanguageKey = (payload: any) =>
  sendPut("/cms/language/update-language-key", payload);

export const createResource = (payload: any) =>
  sendPost("/cms/resource", payload);
export const getListResouce = (payload: any) =>
  sendGet("/cms/resource", payload);
export const updateResource = (id: number, payload: any) =>
  sendPut(`/cms/resource/${id}`, payload);
export const updateStatusResource = (id: number, payload: any) =>
  sendPut(`/cms/resource/update-status/${id}`, payload);
export const getDetailResource = (id: number) => sendGet(`/cms/resource/${id}`);
