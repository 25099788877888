import configs from "config";
import { message } from "antd";
import CustomNotification from "components/CustomNotification";
import i18n from "i18next";
import {
  CardStampStatus,
  CardStampType,
  COMMON_STATUS,
  CouponStatus,
  CouponType,
  Gender,
  MemberCouponCMS,
} from "common/enum";
import { statusDefault, text } from "utils/const";
import * as XLSX from "xlsx";
import * as FileSaver from "file-saver";
import moment from "moment";
import { tmpdir } from "os";
import { isEmpty } from "lodash";

export const handleErrorMessage = (error: any) => {
  CustomNotification({
    type: "error",
    message: i18n.t("common.error"),
    description: getErrorMessage(error),
  });
  if (configs.APP_ENV !== "prod") {
    // tslint:disable-next-line: no-console
    console.log(error);
  }
};
export const handleSuccessMessage = (text: string) => {
  CustomNotification({
    type: "success",
    message: text,
  });
};

export const getErrorMessage = (error: any) => {
  return error?.response?.data?.errorMessage || "Something went wrong!";
};

export const assignDomain = (url: string) => {
  if (url?.startsWith("http")) return url;
  return `${configs.AWS_DOMAIN}/${url}`;
};

export const getDataFile = (file: File): Promise<string> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.addEventListener("load", () => resolve(reader.result as string));
    reader.readAsText(file);
  });

export const formatPriceToJpString = (price: number) => price?.toLocaleString("ja-JP");

export const getTextOfStatus = (status: number) => {
  if (status === COMMON_STATUS.ACTIVE) {
    return statusDefault.active;
  }
  return statusDefault.inactive;
};

export const trimData = (data: any) => {
  const resultData: any = {};
  for (const property in data) {
    if (typeof data[property] === "string") {
      resultData[property] = data[property].trim();
    } else {
      resultData[property] = data[property];
    }
  }
  return resultData;
};

export const getTypeCoupon = (type: number) => {
  if (type === CouponType.COMPANY) {
    return text.couponTypeCompany;
  }
  return text.couponTypeRestaurant;
};

export const getStatusCoupon = (status: number) => {
  if (status === CouponStatus.AVAILABLE) {
    return text.couponStatusActive;
  }
  if (status === CouponStatus.ADD_TO_STAMP) {
    return text.couponStatusAddToStamp;
  }
  if (status === CouponStatus.ADD_TO_NOTI) {
    return text.couponStatusAddToNoti;
  }
  if (status === CouponStatus.PUBLISHED) {
    return text.couponStatusPublished;
  }
  return text.couponStatusOutdate;
};

export const getGender = (gender: number) => {
  if (gender === Gender.MALE) {
    return text.male;
  }
  return text.female;
};

export const getStatusUserCoupon = (status: number) => {
  if (status === MemberCouponCMS.AVAILABLE) {
    return text.couponUserStatusHasUse;
  }
  if (status === MemberCouponCMS.USED) {
    return text.couponUserStatusUsed;
  }

  return text.couponUserStatusOutdate;
};

export const getTypeCardStamp = (type: number) =>
  type === CardStampType.TRADE ? text.stampTypeTrade : text.stampTypeAccumulation;

export const getStatusCardStamp = (status: number) => {
  if (status === CardStampStatus.AVAILABLE) {
    return text.stampStatusActive;
  }
  if (status === CardStampStatus.ADD_TO_NOTI) {
    return text.stampStatusAddToNoti;
  }
  if (status === CardStampStatus.PUBLISHED) {
    return text.stampStatusPublished;
  }
  return text.stampStatusOutdate;
};
export const convertDateUtcToLocal = (dateUtc: any) => {
  if (!dateUtc) return;
  const stillUtc = moment.utc(dateUtc).toDate();
  // return moment(stillUtc).local().format("YYYY-MM-DD HH:mm:ss");
  return moment(stillUtc).local().format("YYYY-MM-DD");
};

export const formatDate = (date: Date | number) => {
  return moment(date).format("YYYY-MM-DD HH:mm:ss");
};

export const convertDate = (dateUtc: any) => {
  if (!dateUtc) return;
  return moment(dateUtc).startOf("D").format("YYYY-MM-DD");
};

export const convertEndDate = (dateUtc: any) => {
  if (!dateUtc) return;
  return moment(dateUtc).subtract(1, "second").format("YYYY-MM-DD");
};
export const convertMilliSecondsToSecond = (milliSeconds: number) => {
  if (!milliSeconds) return 0;
  const date = new Date(milliSeconds);
  return date.getSeconds();
};
export const convertSecondToHour = (second: number) => {
  if (!second) return 0;
  const date = new Date(second * 1000).toISOString().substring(11, 19);
  return date;
};
export const convertMeterToKm = (meter: number) => {
  if (!meter) return "0.00";
  const km = meter / 1000;
  return formatDistanceFloat(km);
};
export function getBase64(file: File) {
  return new Promise<string>((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = (error) => reject(error);
  });
}
export function convertHoursToMilliSecond(hours: number) {
  return Math.floor(hours * 60 * 60 * 1000);
}
export function convertMilliSecondsToHours(milliSeconds: number) {
  return Math.floor(milliSeconds / 60 / 60 / 1000);
}
export function getValueKey(keyLanguages: string, translations: any) {
  const result = translations?.find((x: any) => x?.code === keyLanguages);
  return result?.value;
}

export function cutLongString(str: string, length = 10) {
  const result = str?.length > length ? (str || "").substring(0, length).trim() + "..." : str;
  return result;
}

export const getBrandId = (brands: any[], key: string) => {
  const brandObj = brands?.find((el) => el?.name === key);
  const brandId = brandObj ? brandObj?.id : null;
  return brandId;
};

export const exportToCSV = (csvData: any, fileName: string, sheetName: string) => {
  const fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF8MB4";
  const fileExtension = ".xlsx";

  const ws1 = XLSX.utils.json_to_sheet(csvData);
  const wb = XLSX.utils.book_new();

  XLSX.utils.book_append_sheet(wb, ws1);

  const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
  const data = new Blob([excelBuffer], { type: fileType });
  FileSaver.saveAs(data, fileName + fileExtension);
};

export const converSecondToHours = (second: number) => {
  if (!second) return "00:00";

  if (second < 3600) {
    return new Date(second * 1000).toISOString().substring(14, 19);
  }
  return new Date(second * 1000).toISOString().substring(11, 19);
};

export const ONE_SECOND_MS = 1000;
export const ONE_MINUTE_SEC = 60;
export const ONE_HOUR_SEC = 3600;

export const formatTimeNumber = (num: any = 0) => {
  if (Number(num) && Number(num) !== 0) {
    const newNum = Number(num);
    return newNum < 10 ? `0${newNum.toString()}` : newNum.toString();
  }
  return "00";
};

export const getPaveAverage = (time: number, distance: number) => {
  if (!time || !distance) return "00:00";
  const pave = (1000 * time) / distance;
  return converSecondToHours(pave);

  // const pave = (1000 * time) / (distance * 60);
  // return (Math.round(pave * 100) / 100).toFixed(2);
};

export const formatDistanceFloat: any = (distance = 0) => {
  return parseFloat(`${distance || 0}`).toFixed(2) || 0;
};

const ONE_MINUTE = 60;
const ONE_HOUR = 3600;
const ONE_SECOND = 1000;
export const formatNumberTimer = (num: any = 0) => {
  if (Number(num) && Number(num) !== 0) {
    const newNum = Number(num);
    return newNum < 10 ? `0${newNum.toString()}` : newNum.toString();
  }
  return "00";
};
export const formatTimerPace = (s: number) => {
  const ms = s * ONE_SECOND;
  if (!ms) {
    return "0:00";
  }
  const seconds = Math.floor((ms / ONE_SECOND) % ONE_MINUTE);
  const minutes = Math.floor((ms / ONE_SECOND / ONE_MINUTE) % ONE_MINUTE);
  const hours = Math.floor((ms / ONE_SECOND / ONE_HOUR) % 24);
  if (hours) {
    return `${hours}:${formatNumberTimer(minutes)}:${formatNumberTimer(seconds)}`;
  }
  return `${minutes || 0}:${formatNumberTimer(seconds)}`;
};
export const A_YEAR_DAY = 365;

export const pageSize = 50;

export const convertListRank = (listRank: any) => {
  let rank: any = {};
  listRank?.forEach((item: any) => {
    rank[item.id] = item?.name;
  });
  return rank;
};

export const convertListRankChild = (listRank: any) => {
  let listRankChild: any = [];
  let rankChild: any = {};

  listRank?.forEach((item: any) => {
    listRankChild.push(item?.rankChildren);
  });

  listRankChild = listRankChild.flat(Infinity);

  listRankChild.forEach((item: any) => {
    rankChild[item.id] = item.name;
  });
  return rankChild;
};

export const padTo2Digits = (num: number) => {
  return num.toString().padStart(2, "0");
};

export const convertMsToHM = (milliseconds: number) => {
  let seconds = Math.floor(milliseconds / 1000);
  let minutes = Math.floor(seconds / 60);
  let hours = Math.floor(minutes / 60);

  seconds = seconds % 60;
  // 👇️ if seconds are greater than 30, round minutes up (optional)
  minutes = seconds >= 30 ? minutes + 1 : minutes;

  minutes = minutes % 60;

  // 👇️ If you don't want to roll hours over, e.g. 24 to 00
  // 👇️ comment (or remove) the line below
  // commenting next line gets you `24:00:00` instead of `00:00:00`
  // or `36:15:31` instead of `12:15:31`, etc.
  hours = hours % 24;
  return `${padTo2Digits(hours)}:${padTo2Digits(minutes)}:${padTo2Digits(seconds)}`;
};
// export const convertTimeToSpeed = () => {

// }

export const formatDateString = "YYYY-MM-DD";

export const handleDisableDate = (current: any) => {
  const time = moment().startOf("day");
  return current && current < time;
};

export const emptyValue = [null, undefined];

export const convertPayload = (obj: any) => {
  const listKey = Object.keys(obj);
  listKey?.forEach((item: any, index: number) => {
    if (emptyValue.includes(obj[item])) {
      delete obj[item];
    }
  });
  return obj;
};

export function getRandomItemInArray<T>(array: T[]): T | undefined {
  if (!array?.length) return;
  const index = Math.floor(Math.random() * array.length);

  return array[index];
}

export function runLottery(totalWinner: number, data: any[]) {
  if (!data?.length) return [];
  const results = [] as any[];

  let memberIds = data.reduce((acc, cur) => {
    const arr = new Array(cur.totalLotteryMember).fill(cur.member.id);
    acc.push(...arr);
    return acc;
  }, [] as number[]);

  while (totalWinner > 0 && memberIds.length > 0) {
    const winner: any = getRandomItemInArray(memberIds);
    memberIds = memberIds.filter((item: number) => item !== winner);
    totalWinner = totalWinner - 1;
    results.push(winner);
  }

  return data.filter((item) => results.includes(item.member.id));
}

export const PATTERN_INTEGER = /^[0-9]{1,45}$/;

export const convertDataCSV = (historyData: any[], t: any) => {
  if (isEmpty(historyData))
    return [
      {
        [t("giftManager.stt")]: "",
        [t("giftManager.name")]: "",
        [t("giftManager.lastFurigana")]: "",
        [t("giftManager.firstFurigana")]: "",
        [t("giftManager.phone")]: "",
        [t("giftManager.mail")]: "",
        [t("giftManager.zipCode")]: "",
        [t("giftManager.address1")]: "",
        [t("giftManager.address2")]: "",
      },
    ];
  const convertedData = historyData?.map((el: any, index: number) => ({
    [t("member.stt")]: index + 1,
    [t("giftManager.name")]: el?.member?.name,
    [t("giftManager.lastFurigana")]: el?.lastNameFurigana,
    [t("giftManager.firstFurigana")]: el?.firstNameFurigana,
    [t("giftManager.phone")]: `${el?.phone}`,
    [t("giftManager.mail")]: el?.email,
    [t("giftManager.zipCode")]: `${el?.zipCode}`,
    [t("giftManager.address1")]: el?.address,
    [t("giftManager.address2")]: el?.address2,
  }));
  return convertedData;
};

export const checkEmptyImage = (images: any) => {
  const values = Object.values(images);
  const isEmpty = values.every((item: any) => !item);
  return isEmpty;
};
