import { handleErrorMessage } from "./index";
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { getListLanguageByEnvironment } from "api/resource";
import ja from 'locales/ja.json'

i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    fallbackLng: "ja",
    debug: false,
    interpolation: {
      escapeValue: false,
    },
    resources: {
      ja: { translation: ja }
    },
  });
export const setResourceI18n = async () => {
  try {
    const { data } = await getListLanguageByEnvironment({ environment: "CMS" });
    const listLanguage = Object.keys(data);
    listLanguage.forEach((language: any) => {
      i18n.addResourceBundle(
        language,
        "translation",
        data[language],
        true,
        true
      );
    });
  } catch (error) {
    handleErrorMessage(error);
  }
};
