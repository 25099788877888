import { getListResource } from "api/common";
import { useQuery } from "react-query";

export default function useResources(enabled = false) {
  const { data: resources, refetch: refetchResources } = useQuery<{ [key: string]: any }>(
    "resources",
    getListResource,
    { enabled }
  );

  return { resources, refetchResources };
}
