import { sendGet, sendPost, sendPut } from "./axios";

export const getListContact = (params: any) => sendGet("/cms/contact/list-contact", params);

export const updateContact = (id: any, params: any) => sendPut(`/cms/contact/update-contact/${id}`, params);
export const replyContact = (id: any, params: any) => sendPost(`/cms/contact/reply/${id}`, params);
export const getDetailContact = (id: number) => sendGet(`/cms/contact/${id}`);

export const readContact = () => sendPost("/cms/contact/read-contact");

export const isNewContact = () => sendGet("/cms/contact/check-new-contact");
