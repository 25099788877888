import React, { useEffect, useState } from "react";
import "./App.scss";
import { unstable_HistoryRouter as HistoryRouter } from "react-router-dom";
import { createBrowserHistory } from "history";
import { QueryClientProvider, QueryClient } from "react-query";
import RootWrapper from "wrappers/RootWrapper";
import { setResourceI18n } from "helper/i18n";

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      cacheTime: 24 * 3600 * 1000, // cache for 1 day
    },
  },
});

export const history = createBrowserHistory();
function App() {
  const [loadingLang, setLoadingLang] = useState(true);
  useEffect(() => {
    const getLanguage = async () => {
      await setResourceI18n();
      setLoadingLang(false);
    };
    getLanguage();
  }, []);
  if (loadingLang) return null;
  return (
    <QueryClientProvider client={queryClient}>
      <HistoryRouter history={history}>
        <RootWrapper />
      </HistoryRouter>
    </QueryClientProvider>
  );
}

export default App;
