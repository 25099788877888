import { getProfile } from "api/authentication";
import { getListResource } from "api/common";
import { useQuery } from "react-query";

export default function useProfile(enabled = false) {
  const { data: profile, refetch: refetchProfile } = useQuery<any>(
    "profile",
    getProfile,
    { enabled }
  );
  return { profile: profile?.data, refetchProfile };
}
