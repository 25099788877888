import { Menu } from "antd";
import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";

import Icon, {
  BarChartOutlined,
  BookOutlined,
  NotificationOutlined,
  SettingOutlined,
  TeamOutlined,
  GiftOutlined,
  ScheduleOutlined,
  TrademarkCircleOutlined,
} from "@ant-design/icons";
import Sider from "antd/lib/layout/Sider";
import { BellIcon, Language, PhoneIcon, RunningIcon, ShoesIcon } from "assets/icon";
import { useTranslation } from "react-i18next";
import styles from "./styles.module.scss";
import useNewContact from "hooks/useNewContact";
import classNames from "classnames";

const { SubMenu } = Menu;

export default function SideNav() {
  const { t } = useTranslation();
  const location = useLocation();
  const [collapsed, setCollapsed] = useState(false);
  const toggleCollapsed = () => setCollapsed(!collapsed);
  const [selectedKey, setSelectedKey] = useState("1");
  const SIDE_NAV_WIDTH = 204;
  const { newContact } = useNewContact();
  const routes = [
    {
      key: "1",
      text: t("nav.campaign_nav"),
      url: "/campaign",
      icon: <Icon component={() => <NotificationOutlined />} />,
      visible: true,
    },
    {
      key: "2",
      text: t("nav.rankCondition_nav"),
      url: "/rank-condition",
      icon: <Icon component={() => <BarChartOutlined />} />,
      visible: true,
    },
    {
      key: "3",
      text: t("nav.guild"),
      icon: <Icon component={() => <BookOutlined />} />,
      visible: true,
      children: [
        {
          key: "guild",
          text: t("nav.guild"),
          url: "/guides",
          visible: true,
        },
        {
          key: "guildAchievementYear",
          text: t("nav.guildAchievementYear"),
          url: "/guild-achievement-year",
          visible: true,
        },
      ],
    },

    {
      key: "5",
      text: t("nav.member_nav"),
      url: "/member",
      icon: <Icon component={() => <TeamOutlined />} />,
      visible: true,
      children: [
        {
          key: "5.1",
          text: t("nav.member"),
          url: "/member",
          visible: true,
        },
        {
          key: "5.2",
          text: t("nav.memberAchievement"),
          url: "/member-achievement",
          visible: true,
        },
        {
          key: "5.3",
          text: t("nav.detail_campaign"),
          url: "/campaign-detail",
          visible: true,
        },
      ],
    },

    {
      key: "6",
      text: t("nav.run_nav"),
      icon: <Icon component={() => <RunningIcon />} />,
      visible: true,
      children: [
        {
          key: "6.1",
          text: t("nav.achievement"),
          url: "/run-achievement",
          visible: true,
        },
        {
          key: "6.2",
          text: t("nav.run_abnormal"),
          url: "/run-abnormal",
          visible: true,
        },
        {
          key: "6.3",
          text: t("nav.nav_runDemo"),
          url: "/run-demo",
          visible: true,
        },
      ],
    },
    {
      key: "7",
      text: t("nav.span_notification"),
      icon: <Icon component={() => <BellIcon />} />,
      visible: true,
      children: [
        {
          key: "7.1",
          text: t("nav.notification_nav"),
          url: "/notification",
          visible: true,
        },
        {
          key: "7.2",
          text: t("nav.notification_delay_nav"),
          url: "/notification-delay",
          visible: true,
        },
      ],
    },

    {
      key: "9",
      text: t("nav.nav_languages"),
      url: "/languages",
      icon: <Icon component={() => <Language />} />,
      visible: true,
    },
    {
      key: "11",
      text: t("nav.shoes_nav"),
      icon: <Icon component={() => <ShoesIcon />} />,
      children: [
        {
          key: "11.1",
          text: t("nav.shoes_nav"),
          url: "/shoes",
          visible: true,
        },
        {
          key: "11.2",
          text: t("nav.shoes_model_nav"),
          url: "/shoes-model",
          visible: true,
        },
        {
          key: "11.3",
          text: t("nav.brand"),
          url: "/resource/brand",
          visible: true,
        },
      ],
    },
    {
      key: "12",
      text: t("nav.contact"),
      url: "/contacts",
      icon: <Icon component={() => <PhoneIcon />} />,
      showNotification: !!newContact,
      visible: true,
    },
    {
      key: "gift-code",
      text: t("nav.giftCode"),
      url: "/gift-code",
      icon: <Icon component={() => <GiftOutlined />} />,
      visible: true,
    },
    {
      key: "14",
      text: t("nav.gift"),
      // url: "/gift-coin",
      icon: <Icon component={() => <TrademarkCircleOutlined />} />,
      children: [
        {
          key: "14.7",
          text: t("nav.managerCompany"),
          url: "/manager-company",
          visible: true,
        },
        {
          key: "14.1",
          text: t("nav.giftManager"),
          url: "/gift-manager",
          visible: true,
        },

        {
          key: "14.2",
          text: t("nav.giftCoin"),
          url: "/gift-coin",
          visible: true,
        },
        {
          key: "14.3",
          text: t("nav.giftLottery"),
          url: "/gift-lottery",
          visible: true,
        },
        {
          key: "14.4",
          text: t("nav.giftHistory"),
          url: "/gift-history",
          visible: true,
        },
        {
          key: "14.5",
          text: t("nav.winnerHistory"),
          url: "/winner-history",
          visible: true,
        },
        {
          key: "14.6",
          text: t("nav.coinAcquisitionHistory"),
          url: "/coin-acquisition-history",
          visible: true,
        },
        {
          key: "14.8",
          text: t("nav.randomGift"),
          url: "/random-gift",
          visible: true,
        },
      ],
    },
    {
      key: "15",
      text: t("nav.quest"),
      url: "/quest-manager",
      icon: <Icon component={() => <ScheduleOutlined />} />,
      visible: true,
    },
    {
      key: "13",
      text: t("nav.system"),
      icon: <Icon component={() => <SettingOutlined />} />,
      children: [
        {
          key: "13.2",
          text: t("nav.contact"),
          url: "/resource/contact",
          visible: true,
        },
        {
          key: "13.3",
          text: t("nav.faq"),
          url: "/resource/faq",
          visible: true,
        },
        {
          key: "13.4",
          text: t("nav.term"),
          url: "/resource/term",
          visible: true,
        },
        {
          key: "13.5",
          text: t("nav.policy"),
          url: "/resource/policy",
          visible: true,
        },
        {
          key: "feedback",
          text: t("nav.feedback"),
          url: "/resource/feedback",
          visible: true,
        },
        {
          key: "app_guide",
          text: t("nav.appGuide"),
          url: "/resource/app-guide",
          visible: true,
        },
        {
          key: "13.6",
          text: t("nav.banner"),
          url: "/resource/guild-slide",
          visible: true,
        },
      ],
    },
  
  ];
  useEffect(() => {
    routes.forEach((route) => {
      if (location.pathname.startsWith(route.url || "###")) {
        setSelectedKey(route.key);
      }
      if (route.children) {
        route.children.forEach((childRoute) => {
          if (location.pathname.startsWith(childRoute.url || "###")) {
            setSelectedKey(childRoute.key);
          }
        });
      }
    });
  }, [location.pathname]);

  return (
    <div className={styles.side_nav}>
      <div className={styles.logo_container}>
        <Link className={styles.logo} to="/campaign">
          Runcrew
        </Link>
      </div>
      <Sider width={SIDE_NAV_WIDTH} className={`${styles.side_nav__sider} scroll`}>
        <Menu selectedKeys={[selectedKey]} defaultOpenKeys={["3"]} mode="inline">
          {routes.map((route) => {
            if (route.visible === false) {
              return null;
            }
            if (route.children) {
              return (
                <SubMenu key={route.key} icon={route.icon} title={route.text}>
                  {route.children?.map((childRoute) => {
                    if (childRoute.visible === false) {
                      return null;
                    }
                    return (
                      <Menu.Item
                        key={childRoute.key}
                        className={classNames({ [styles.showNotification]: route?.showNotification })}
                      >
                        <Link to={childRoute.url}>{childRoute.text}</Link>
                      </Menu.Item>
                    );
                  })}
                </SubMenu>
              );
            }
            return (
              <Menu.Item
                className={classNames({ [styles.showNotification]: route?.showNotification })}
                key={route.key}
                icon={route.icon}
              >
                <Link to={route.url}>{route.text}</Link>
              </Menu.Item>
            );
          })}
        </Menu>
      </Sider>
    </div>
  );
}
