export enum PERMISSIONS {
  RESOURCE_MANAGEMENT = 1,
  LANGUAGE_MANAGEMENT = 2,
  MEMBER_MANAGEMENT = 3,
  RESTAURANT_MANAGEMENT = 4,
  NOTIFICATION_MANAGEMENT = 5,
  USER_MANAGEMENT = 6,
  ROLE_MANAGEMENT = 7,
}

export enum IsSystem {
  Yes = 1,
  No = 0,
}

export enum IsVisible {
  Yes = 1,
  No = 0,
}

export enum COMMON_STATUS {
  ACTIVE = 1,
  INACTIVE = 0,
  PAUSE = 2,
  FAIL = 3,
}
export enum STATUS_RUN_ACHIEVEMENT {
  RUN = 1,
  FREE = 2,
}
export enum TYPE_RANK {
  LEGEND = "LEGEND",
}

export enum DishOptionType {
  RADIO_BOX = 1,
  COUNTER = 2,
}
export enum LANGUAGE {
  JA = "ja",
}
export enum ENVIRONMENT {
  CMS = "CMS",
}
export enum NotificationTargetType {
  COMMON = 1,
  ALL = 2,
  GUILD = 3,
  GIFT_CODE = 4,
}
export enum NotificationType {
  COMMON = 1,
  ADMIN_SEND = 2,
}
export enum CouponStatus {
  AVAILABLE = 1,
  OUTDATE = 2,
  ADD_TO_STAMP = 3,
  ADD_TO_NOTI = 4,
  PUBLISHED = 5,
}

export enum CouponType {
  COMPANY = 1,
  RESTAURANT = 0,
}

export enum DateType {
  EXPIRED_DATE = 1,
  NO_EXPIRED_DATE = 2,
}

export enum DiscountType {
  ALL_ORDER = 1,
  EACH_DISH = 2,
}

export enum CouponDishType {
  SETTING_DISCOUNT = 1,
  FREE = 2,
}

export enum TabDetail {
  PROFILE = "1",
  ORDER_HISTORY = "2",
  COUPON = "3",
  CARD_STAMP = "4",
}

export enum Gender {
  MALE = 1,
  FEMALE = 2,
}

export enum MemberCouponCMS {
  USED = 1,
  AVAILABLE = 2,
  OUTDATE = 3,
}
export enum RunType {
  INDIVIDUAL = 1,
  GUILD = 2,
  DEMO = 3,
  ALL = "",
}
export enum RunStatus {
  INACTIVE = 0,
  ACTIVE = 1,
  RUNNING = 2,
  FAIL = 3,
  UPDATE_RANK_IN_CAMPAIGN = 4,
  ABNORMAL = 5,
}
export enum CardStampStatus {
  AVAILABLE = 1,
  OUTDATE = 2,
  ADD_TO_NOTI = 3,
  PUBLISHED = 4,
}

export enum CardStampType {
  TRADE = 1,
  ACCUMULATE = 2,
}

export enum SaveStampType {
  AUTO = 1,
  SELECT = 2,
}

export enum StampExpiryType {
  HAS_OUTDATE = 1,
  INFINITY = 2,
}

export enum StampFrameNumber {
  LIMIT = 1,
  INFINITY = 2,
}

export enum Pattern {
  PATTERN_1 = 5,
  PATTERN_2 = 3,
  PATTERN_3 = 7,
}

export enum ContactState {
  PENDING = 1,
  ANSWERED = 2,
}

export enum ResourceType {
  TERM = 1,
  POLICY = 2,
  HELP = 3,
  BRAND = 4,
  CONTACT = 5,
  FAQ = 6,
  GUILD_SLIDE = 7,
  APP_GUIDE = 8,
  FEEDBACK = 9,
}

export enum GuildRole {
  ADMIN = 1,
  SUB_ADMIN = 2,
  MEMBER = 3,
}

export enum BannerType {
  NONE = 0,
  LINK = 1,
  REDIRECT = 2,
}

export enum BannerRedirectType {
  APP_GUIDE = "1",
  GUILD_HOME = "2",
  RUN = "3",
  LIST_MENU_RUN = "4",
  LIST_EVENT = "5",
  SHOES_REGISTER = "6",
  CHANGE_PLAN_APP = "7",
  GIFT = "8",
}
export enum ApprovalRunType {
  ACCEPT = 1,
  REJECT = 2,
  RUN_INDIVIDUAL = 3,
}

export enum AcquiredPoint {
  ASSIGNMENT_1 = 1,
  ASSIGNMENT_2 = 2,
  ASSIGNMENT_3 = 3,
  ASSIGNMENT_4 = 4,
  ASSIGNMENT_5 = 5,
}
export enum CAMPAIGN {
  ACTIVE = 1,
  INACTIVE = 0,
  ALL = 2,
}

export enum DISTANCE {
  MIN = 5,
  KM = 1000,
}
export enum PostType {
  SYSTEM = 1,

  GARMIN = 2,

  GOOGLE = 3,

  APPLE = 4,

  COROS = 5,
}

export enum GiftType {
  LIMIT = 1,
  RANDOM = 2,
}

export enum TypeAction {
  ADD = "add",
  UPDATE = "update",
}

export enum TypeSettingViewStatus {
  ACTIVE = 1,
  INACTIVE = 0,
}

export enum GiftState {
  NOT_DELIVERY = 1,
  DELIVERED = 2,
  ALL = "",
}

export enum LimitBuyTypeGiftExchange {
  INACTIVE = 0,
  ACTIVE = 1,
}
export enum LimitTimeGiftExchange {
  INACTIVE = 0,
  ACTIVE = 1,
}

export enum CoinMissionType {
  CAMPAIGN_FINISHED = 1,
  WEEKLY_CREATE_POST_WITH_TITLE = 2,
  DAILY_LIKE_POST = 3,
  WEEKLY_SHARE_POST_SOCIAL = 4,
  WEEKLY_CREATE_POST_WITH_SHOES = 5,
  CAMPAIGN_FIRST_WEEK = 6,
  CAMPAIGN_SECOND_WEEK = 7,
  BETWEEN_CAMPAIGN = 8,
  DAILY_ADS_LOTTERY = 9,
}

export enum CoinNotification {
  ACTIVE = 1,
  INACTIVE = 0,
}

export enum OptionGift {
  ACTIVE = 1,
  INACTIVE = 0,
  MAX_OPTION = 10,
}

export enum Number {
  MAX_NUMBER = 999999999999999,
  MIN_NUMBER = 0,
}

export enum Mission {
  MAX_OPTION = 5,
}
