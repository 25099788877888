export const emailRegex = /^(\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+)$/;
export const phoneRegex = /^\d{10,12}$/;
export const defaultTake = 10;
export const PAGE_SIZE = 10;
export const statusDefault = {
  active: "アプリ表示",
  inactive: "非表示",
};
export const numberRegex = /^[1-9]\d*$/;

export const text = {
  optionAll: "すべて",
  fieldIsRequired: "Field is required",
  confirmDeleteMenu: "商品を削除しますか？",
  seeMore: "もっと見る",
  unlimited: "無制限",
  couponTypeCompany: "計上",
  couponTypeRestaurant: "非計上",
  couponStatusActive: "有効",
  couponStatusOutdate: "有効期限切れ",
  couponStatusAddToStamp: "スタンプカードに追加済み",
  couponStatusAddToNoti: "通知に追加済み",
  couponStatusPublished: "発行済み",
  confirmDeleteCoupon: "Confirm delete coupon",
  couponExpire: " 設定ある",
  couponNoExpire: "無制限",
  couponAllOrder: "注文全体用の割引",
  couponEachDish: "商品割引",
  couponDishDiscount: "設定あり",
  couponDishFree: "無料",
  male: "男性",
  female: "女性",
  couponUserStatusUsed: "使用済み",
  couponUserStatusHasUse: "使用可能",
  couponUserStatusOutdate: "有効期限切れ",
  stampStatusActive: "有効",
  stampStatusOutdate: "有効期限切れ",
  stampStatusAddToNoti: "通知に追加済み",
  stampStatusPublished: "発行済み",
  stampTypeTrade: "交換型スタンプカード",
  stampTypeAccumulation: "累計型スタンプカード",
  saveStampTypeAuto: "自動付与スタンプカード",
  saveStampTypeSelect: "選択付与スタンプカード",
  stampExpiryTypeInfinity: "無期限",
  stampExpiryTypeHasOutdate: "設定あり",
  stampFrameNumberInfinity: "設定なし（無制限）",
  stampFrameNumberLimit: "設定あり",
  stampPattern1: "横５個×縦ｎ個",
  stampPattern2: "横３個×縦ｎ個",
  stampPattern3: "横７個×縦ｎ個",
  validateMin1: "Min is 1",
  validatePositionUnique: "Position must be unique",
  validatePositionMax: "Position must be low more than box amount",
};
